body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'ploni';
  font-weight: 400;
  font-style: normal;
  src: url('assets/fonts/ploni-regular-aaa.woff2') format('woff2'),
    url('assets/fonts/ploni-regular-aaa.woff') format('woff');
}

@font-face {
  font-family: 'ploni';
  font-weight: 600;
  font-style: normal;
  src: url('assets/fonts/ploni-demibold-aaa.woff2') format('woff2'),
    url('assets/fonts/ploni-demibold-aaa.woff') format('woff');
}

@font-face {
  font-family: 'ploni';
  font-weight: 700;
  font-style: normal;
  src: url('assets/fonts/ploni-bold-aaa.woff2') format('woff2'), url('assets/fonts/ploni-bold-aaa.woff') format('woff');
}

.Toastify__toast {
  font-family: 'ploni';
}
